import React, { useState } from "react";

import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";
import SaveButton from "../../components/Buttons/SaveButton";
import { useRecoilState } from "recoil";
import {
  confirmationDialogState,
  dialogState,
  handleErrorState,
  snackBarState,
  loadingOverlayState,
} from "../../GlobalAtoms";
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";
import ReproveButton from "../../components/Buttons/ReproveButton";

// @material-ui components


const StoreEdit = (props: any) => {
  const fields: any = {
    name: 'Nome da Loja',
    initials: 'Sigla',
    corporate_name: 'Razão Social',
    tax_id: 'CNPJ',
    contact: 'Responsável',
    phone: 'Telefone',
    address: 'Endereço',
    number: 'Número',
    address2: 'Complemento',
    district: 'Bairro',
    city: 'Cidade',
    province: 'Estado',
    postal_code: 'CEP',
  }

  const [values, setValue] = useState({
    id: props.editDialogData.id,
    company_id: props.editDialogData.company_id,
    name: props.editDialogData.name,
    initials: props.editDialogData.initials,
    corporate_name: props.editDialogData.corporate_name,
    tax_id: props.editDialogData.tax_id,
    contact: props.editDialogData.contact,
    phone: props.editDialogData.phone,
    total_per_week: props.editDialogData.total_per_week_str,
    address: props.editDialogData.address,
    number: props.editDialogData.number,
    address2: props.editDialogData.address2,
    district: props.editDialogData.district,
    city: props.editDialogData.city,
    province: props.editDialogData.province,
    postal_code: props.editDialogData.postal_code,
    quantity_coin_5: props.editDialogData.quantity_coin_5,
    quantity_coin_10: props.editDialogData.quantity_coin_10,
    quantity_coin_25: props.editDialogData.quantity_coin_25,
    quantity_coin_50: props.editDialogData.quantity_coin_50,
    quantity_coin_100: props.editDialogData.quantity_coin_100,
    quantity_cash_2: props.editDialogData.quantity_cash_2,
    quantity_cash_5: props.editDialogData.quantity_cash_5,
    quantity_cash_10: props.editDialogData.quantity_cash_10,
    quantity_cash_20: props.editDialogData.quantity_cash_20,
    quantity_cash_50: props.editDialogData.quantity_cash_50,
    quantity_cash_100: props.editDialogData.quantity_cash_100,
    quantity_cash_200: props.editDialogData.quantity_cash_200,
    coin_is_enabled: props.editDialogData.coin_is_enabled,
    coin_use_from_company: props.editDialogData.coin_use_from_company,
    coin_delivery_fee: props.editDialogData.coin_delivery_fee,
    coin_per_thousand_fee: props.editDialogData.coin_per_thousand_fee,
    cash_is_enabled: props.editDialogData.cash_is_enabled,
    cash_use_from_company: props.editDialogData.cash_use_from_company,
    cash_delivery_fee: props.editDialogData.cash_delivery_fee,
    is_enabled: props.editDialogData.is_enabled,
    country: props.editDialogData.country,
    request_day: 0,
    develivery_day: 0,
  });

  const [storeChange, setStoreChange] = useState(props.editDialogData.store_change);

  const [storeWeekdays, setStoreWeekdays] = useState(props.editDialogData.store_weekdays);

  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/stores/${props.editDialogData.id}`;
    const headers = getHeaders();


    const dataSave = JSON.stringify({
      id: values.id,
      company_id: values.company_id,
      name: values.name,
      initials: values.initials,
      corporate_name: values.corporate_name,
      tax_id: values.tax_id,
      contact: values.contact,
      phone: values.phone,
      total_per_week: values.total_per_week,
      address: values.address,
      number: values.number,
      address2: values.address2,
      district: values.district,
      city: values.city,
      province: values.province,
      postal_code: values.postal_code,
      quantity_coin_5: values.quantity_coin_5,
      quantity_coin_10: values.quantity_coin_10,
      quantity_coin_25: values.quantity_coin_25,
      quantity_coin_50: values.quantity_coin_50,
      quantity_coin_100: values.quantity_coin_100,
      quantity_cash_2: values.quantity_cash_2,
      quantity_cash_5: values.quantity_cash_5,
      quantity_cash_10: values.quantity_cash_10,
      quantity_cash_20: values.quantity_cash_20,
      quantity_cash_50: values.quantity_cash_50,
      quantity_cash_100: values.quantity_cash_100,
      quantity_cash_200: values.quantity_cash_200,
      coin_is_enabled: values.coin_is_enabled,
      coin_use_from_company: values.coin_use_from_company,
      coin_delivery_fee: values.coin_delivery_fee,
      coin_per_thousand_fee: values.coin_per_thousand_fee,
      cash_is_enabled: values.cash_is_enabled,
      cash_use_from_company: values.cash_use_from_company,
      cash_delivery_fee: values.cash_delivery_fee,
      is_enabled: values.is_enabled,
      country: values.country,
    });

    axios
      .put(url, dataSave, { headers })
      .then((response) => {
        const { data } = props;
        data[props.editKey] = {
          id: values.id,
          company_id: values.company_id,
          name: values.name,
          initials: values.initials,
          corporate_name: values.corporate_name,
          tax_id: values.tax_id,
          contact: values.contact,
          phone: values.phone,
          total_per_week: values.total_per_week,
          total_per_week_str: response.data.data.total_per_week_str,
          address: values.address,
          number: values.number,
          address2: values.address2,
          district: values.district,
          city: values.city,
          province: values.province,
          postal_code: values.postal_code,
          quantity_coin_5: values.quantity_coin_5,
          quantity_coin_10: values.quantity_coin_10,
          quantity_coin_25: values.quantity_coin_25,
          quantity_coin_50: values.quantity_coin_50,
          quantity_coin_100: values.quantity_coin_100,
          quantity_cash_2: values.quantity_cash_2,
          quantity_cash_5: values.quantity_cash_5,
          quantity_cash_10: values.quantity_cash_10,
          quantity_cash_20: values.quantity_cash_20,
          quantity_cash_50: values.quantity_cash_50,
          quantity_cash_100: values.quantity_cash_100,
          quantity_cash_200: values.quantity_cash_200,
          coin_is_enabled: values.coin_is_enabled,
          coin_use_from_company: values.coin_use_from_company,
          coin_delivery_fee: values.coin_delivery_fee,
          coin_per_thousand_fee: values.coin_per_thousand_fee,
          cash_is_enabled: values.cash_is_enabled,
          cash_use_from_company: values.cash_use_from_company,
          cash_delivery_fee: values.cash_delivery_fee,
          is_enabled: values.is_enabled,
          country: values.country,
          company_name: response.data.data.company_name,
        };

        props.setData([...data]);

        setSnackBar({
          open: true,
          type: "success",
          message: 'Loja editada com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const storeStoreWeekdays = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/store/weekdays`;
    const headers = getHeaders();


    const dataSave = JSON.stringify({
      store_id: values.id,
      request_day: values.request_day,
      develivery_day: values.develivery_day,
    });

    axios
      .post(url, dataSave, { headers })
      .then((response) => {
        const { data } = props;

        setStoreWeekdays(response.data.data);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Dia e prazo de entrega adicionados com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const deleteWeekday =  async(id: any) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/store/weekdays/${id}`;
    const headers = getHeaders();


    axios
      .delete(url, { headers })
      .then((response) => {
        const { data } = props;

        setStoreWeekdays(response.data.data);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Dia e prazo de entrega deletados com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const approveChanges =  async(id: any) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/store/changes/approve/${id}`;
    const headers = getHeaders();


    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = props;
        data[props.editKey] = response.data.data;

        props.setData([...data]);

        let newValues = { ...response.data.data };
        newValues.total_per_week = response.data.data.total_per_week_str
        setValue(newValues);
        setStoreChange(null);

        setSnackBar({
          open: true,
          type: "success",
          message: 'Alterações aprovadas com sucesso.',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const discardChanges =  async(id: any) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/store/changes/discard/${id}`;
    const headers = getHeaders();


    axios
      .get(url, { headers })
      .then((response) => {
        let newValues = { ...response.data.data };
        newValues.total_per_week = response.data.data.total_per_week_str
        setValue(newValues);
        setStoreChange(null);

        setSnackBar({
          open: true,
          type: "success",
          message: 'Alterações descartadas com sucesso.',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
      });
  };

  return (
    <div>
      {storeChange !== null &&
        <>
          <Typography variant="h6">Solicitações de Edição de Usuário</Typography>
          <DividerWrapper />
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell  align="center"><strong>Campo</strong></TableCell >
                <TableCell  align="center"><strong>Valor Antigo</strong></TableCell >
                <TableCell  align="center"><strong>Valor Novo</strong></TableCell >
              </TableRow>
            </TableHead>
            <TableBody>
              {storeChange.store_change_fields.map((store_change_field: any) => (
                <TableRow>
                  <TableCell  align="center">{fields[store_change_field.field]}</TableCell >
                  <TableCell  align="center">{store_change_field.old_value}</TableCell >
                  <TableCell  align="center">{store_change_field.new_value}</TableCell >
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DividerWrapper />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SaveButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    question: `Você tem certeza que deseja deletar as datas de pedido?`,
                    transition: "default",
                    action: () => approveChanges(storeChange.id),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReproveButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    question: `Você tem certeza que deseja deletar as datas de pedido?`,
                    action: () => discardChanges(storeChange.id),
                    transition: "default",
                  })
                }
                style={{width: '100%'}}
              >&nbsp;&nbsp;Reporvar</ReproveButton>
            </Grid>
            <Grid item xs={12}>
              <DividerWrapper />
            </Grid>
          </Grid>
        </>
      }
      <FormWrapper onSubmit={(event) => store(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Cliente"}
              name={"company_id"}
              id={"company_id"}
              onChange={handleChange("company_id")}
              value={values.company_id}
              required
              select
            >
              {props.companies.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name} - {option.initials}
                </MenuItem>
              ))}
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Nome da Loja"}
              name={"name"}
              id={"name"}
              onChange={handleChange("name")}
              value={values.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Sigla"}
              name={"initials"}
              id={"initials"}
              onChange={handleChange("initials")}
              value={values.initials}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Razão Social"}
              name={"corporate_name"}
              id={"corporate_name"}
              onChange={handleChange("corporate_name")}
              value={values.corporate_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99.999.999/9999-99'}
              name={"tax_id"}
              id={"tax_id"}
              onChange={handleChange("tax_id")}
              value={values.tax_id}
            >
              <TextFieldWrapper label={"CNPJ"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Responsável"}
              name={"contact"}
              id={"contact"}
              onChange={handleChange("contact")}
              value={values.contact}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'(99) 99999-9999'}
              name={"phone"}
              id={"phone"}
              onChange={handleChange("phone")}
              value={values.phone}
            >
              <TextFieldWrapper label={"Telefone"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Valor Total por Pedido"}
              name={"total_per_week"}
              id={"total_per_week"}
              onChange={handleChange("total_per_week")}
              value={values.total_per_week}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Endereço:</h4>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Endereço"}
              name={"address"}
              id={"address"}
              onChange={handleChange("address")}
              value={values.address}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Número"}
              name={"number"}
              id={"number"}
              onChange={handleChange("number")}
              value={values.number}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Complemento"}
              name={"address2"}
              id={"address2"}
              onChange={handleChange("address2")}
              value={values.address2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Bairro"}
              name={"district"}
              id={"district"}
              onChange={handleChange("district")}
              value={values.district}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Cidade"}
              name={"city"}
              id={"city"}
              onChange={handleChange("city")}
              value={values.city}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'aa'}
              name={"province"}
              id={"province"}
              onChange={handleChange("province")}
              value={values.province}
            >
              <TextFieldWrapper label={"UF"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'99.999-999'}
              name={"postal_code"}
              id={"postal_code"}
              onChange={handleChange("postal_code")}
              value={values.postal_code}
            >
              <TextFieldWrapper label={"CEP"} required />
            </InputMaskWrapper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <h4>Serviços Disponíveis</h4>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CheckboxWrapper
                  label={"Aquisição de Troco"}
                  name={"coin_is_enabled"}
                  id={"coin_is_enabled"}
                  onChange={handleChange("coin_is_enabled")}
                  value={values.coin_is_enabled}
                  checked={values.coin_is_enabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxWrapper
                  label={"Usar do Cliente (somente valor)"}
                  name={"coin_use_from_company"}
                  id={"coin_use_from_company"}
                  onChange={handleChange("coin_use_from_company")}
                  value={values.coin_use_from_company}
                  checked={values.coin_use_from_company}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete (R$)"}
                  name={"coin_delivery_fee"}
                  id={"coin_delivery_fee"}
                  onChange={handleChange("coin_delivery_fee")}
                  value={values.coin_delivery_fee}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Custo do Milheiro (R$)"}
                  name={"coin_per_thousand_fee"}
                  id={"coin_per_thousand_fee"}
                  onChange={handleChange("coin_per_thousand_fee")}
                  value={values.coin_per_thousand_fee}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CheckboxWrapper
                  label={"Recolhimento de Cédulas"}
                  name={"cash_is_enabled"}
                  id={"cash_is_enabled"}
                  onChange={handleChange("cash_is_enabled")}
                  value={values.cash_is_enabled}
                  checked={values.cash_is_enabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxWrapper
                  label={"Usar do Cliente (somente valor)"}
                  name={"cash_use_from_company"}
                  id={"cash_use_from_company"}
                  onChange={handleChange("cash_use_from_company")}
                  value={values.cash_use_from_company}
                  checked={values.cash_use_from_company}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete (R$)"}
                  name={"cash_delivery_fee"}
                  id={"cash_delivery_fee"}
                  onChange={handleChange("cash_delivery_fee")}
                  value={values.cash_delivery_fee}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
        </Grid>
        <h4>Limites de Pedido</h4>
      <Grid container spacing={3}>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,05"}
            type="number"
            required
            name={"quantity_coin_5"}
            id={"quantity_coin_5"}
            onChange={handleChange("quantity_coin_5")}
            value={values.quantity_coin_5}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,10"}
            type="number"
            required
            name={"quantity_coin_10"}
            id={"quantity_coin_10"}
            onChange={handleChange("quantity_coin_10")}
            value={values.quantity_coin_10}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,25"}
            type="number"
            required
            name={"quantity_coin_25"}
            id={"quantity_coin_25"}
            onChange={handleChange("quantity_coin_25")}
            value={values.quantity_coin_25}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 0,50"}
            type="number"
            required
            name={"quantity_coin_50"}
            id={"quantity_coin_50"}
            onChange={handleChange("quantity_coin_50")}
            value={values.quantity_coin_50}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 1,00"}
            type="number"
            required
            name={"quantity_coin_100"}
            id={"quantity_coin_100"}
            onChange={handleChange("quantity_coin_100")}
            value={values.quantity_coin_100}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 2,00"}
            type="number"
            required
            name={"quantity_cash_2"}
            id={"quantity_cash_2"}
            onChange={handleChange("quantity_cash_2")}
            value={values.quantity_cash_2}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 5,00"}
            type="number"
            required
            name={"quantity_cash_5"}
            id={"quantity_cash_5"}
            onChange={handleChange("quantity_cash_5")}
            value={values.quantity_cash_5}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 10,00"}
            type="number"
            required
            name={"quantity_cash_10"}
            id={"quantity_cash_10"}
            onChange={handleChange("quantity_cash_10")}
            value={values.quantity_cash_10}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextFieldWrapper
            label={"R$ 20,00"}
            type="number"
            required
            name={"quantity_cash_20"}
            id={"quantity_cash_20"}
            onChange={handleChange("quantity_cash_20")}
            value={values.quantity_cash_20}
          />
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
      </Grid>

      </FormWrapper>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <Typography variant="h6">Datas de Pedido e Entrega</Typography>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <FormWrapper onSubmit={(event) => storeStoreWeekdays(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextFieldWrapper
              label={"Dia do Pedido"}
              name={"request_day"}
              id={"request_day"}
              onChange={handleChange("request_day")}
              value={values.request_day}
              required
              select
            >
              <MenuItem key={'0'} value={'0'}>
                Domingo
              </MenuItem>
              <MenuItem key={'1'} value={'1'}>
                Segunda-Feira
              </MenuItem>
              <MenuItem key={'2'} value={'2'}>
                Terça-Feira
              </MenuItem>
              <MenuItem key={'3'} value={'3'}>
                Quarta-Feira
              </MenuItem>
              <MenuItem key={'4'} value={'4'}>
                Quinta-Feira
              </MenuItem>
              <MenuItem key={'5'} value={'5'}>
                Sexta-Feira
              </MenuItem>
              <MenuItem key={'6'} value={'6'}>
                Sábado
              </MenuItem>
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldWrapper
              label={"Dia da Entrega"}
              name={"develivery_day"}
              id={"develivery_day"}
              onChange={handleChange("develivery_day")}
              value={values.develivery_day}
              required
              select
            >
              <MenuItem key={'0'} value={'0'}>
                Domingo
              </MenuItem>
              <MenuItem key={'1'} value={'1'}>
                Segunda-Feira
              </MenuItem>
              <MenuItem key={'2'} value={'2'}>
                Terça-Feira
              </MenuItem>
              <MenuItem key={'3'} value={'3'}>
                Quarta-Feira
              </MenuItem>
              <MenuItem key={'4'} value={'4'}>
                Quinta-Feira
              </MenuItem>
              <MenuItem key={'5'} value={'5'}>
                Sexta-Feira
              </MenuItem>
              <MenuItem key={'6'} value={'6'}>
                Sábado
              </MenuItem>
            </TextFieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell  align="center"><strong>Dia de Pedido</strong></TableCell >
              <TableCell  align="center"><strong>Dia de Entrega</strong></TableCell >
              <TableCell  align="center"></TableCell >
            </TableRow>
          </TableHead>
          <TableBody>
            {storeWeekdays.map((day: any) => (
              <TableRow>
                <TableCell  align="center">{day.request_day}</TableCell >
                <TableCell  align="center">{day.develivery_day}</TableCell >
                <TableCell  align="center">
                <ReproveButton
                  onClick={() =>
                    setConfirmationDialogState({
                      ...confirmationDialog,
                      open: true,
                      question: `Você tem certeza que deseja deletar as datas de pedido?`,
                      action: () => deleteWeekday(day.id),
                      transition: "default",
                    })
                  }
                />
                </TableCell >
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </div>
  );
};

export default StoreEdit;
